import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/ic_dropdown_arrow.svg";
import { AppContext } from "../../context/user/AppContext";
import {
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
export const Reports = () => {
  const { mainClassB, customerData } = useContext(AppContext);
  const [reportTypes, setReportTypes] = useState([]);
  const [reportConfigure, setReportConfigure] = useState({
    report_type: "",
    title: "",
    email: "",
    from_date: "",
    to_date: "",
  });
  const [errMsg, setErrMsg] = useState({
    report_type: "",
    title: "",
    email: "",
    from_date: "",
    to_date: "",
  });

  useEffect(() => {
    getReportTypes();
  }, []);
  const getReportTypes = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.REPORT_TYPES,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setReportTypes(res.json.report_types
            .filter(e=> e.report_type_id !== 37 && e.report_type_id !== 38));
        }
      })
      .catch((err) => console.log(err));
  };
  const handleGetId = (id) => {
    setReportConfigure({ ...reportConfigure, report_type: id });
  };
  const onGenerateReport = () => {
    if (
      !reportConfigure.report_type === "" ||
      reportConfigure.title === "" ||
      reportConfigure.from_date === "" ||
      reportConfigure.to_date === ""
    ) {
      if (reportConfigure.report_type === "") {
        setErrMsg({ ...errMsg, report_type: "Report Type is required" });
      } else if (reportConfigure.title === "") {
        setErrMsg({ ...errMsg, title: "Please enter title" });
      } else if (reportConfigure.from_date === "") {
        setErrMsg({ ...errMsg, from_date: "Please select from date" });
      } else if (reportConfigure.to_date === "") {
        setErrMsg({ ...errMsg, to_date: "Please select to date" });
      }
    } else {

      let details = JSON.stringify({
        customer_id: customerData.customer_id,
        api_key: customerData.api_key,
        user_id: customerData.user_id,
        report_type: reportConfigure.report_type
          ? reportConfigure.report_type
          : 6,
        email: reportConfigure.email,
        title: reportConfigure.title,
        from_date: reportConfigure.from_date,
        to_date: reportConfigure.to_date,
      });

      postMultipartWithAuthCallWithErrorResponse(ApiConfig.REPORT_ADD, details)
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          swal(res.json.message);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      <main className={` main-section-reports  ${mainClassB}`} id="cxMainB">
        {/* Report tabs */}

        <section className="report-body d-flex justify-content-center">
          <div className="reports-body-section ">
            <h4 className="reports-card-heading">Configure Reports</h4>
            <hr />
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Select Report Type</label>
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, email: "" });
                  setReportConfigure({
                    ...reportConfigure,
                    report_type: Number(e.target.value),
                  });
                }}
              >
                {reportTypes &&
                  reportTypes.length &&
                  reportTypes.map((type, index) => {
                    return (
                      <option value={type.report_type_id}>
                        {type.report_type_name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Enter Email</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, email: "" });
                  setReportConfigure({
                    ...reportConfigure,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Report Title</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, title: "" });
                  setReportConfigure({
                    ...reportConfigure,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">From Date</label>
              <input
                type="date"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, from_date: "" });
                  setReportConfigure({
                    ...reportConfigure,
                    from_date: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">To Date</label>
              <input
                type="date"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, to_date: "" });
                  setReportConfigure({
                    ...reportConfigure,
                    to_date: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex justify-content-center my-4">
              <button className="report-btn" onClick={onGenerateReport}>
                Generate
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
