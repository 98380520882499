import React, { useState } from "react";
import "../../assets/css/ongoingfreights.scss";
import dropdown_arrow_blue from "../../assets/images/megaoffer/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Link } from "react-router-dom";
import freightsimg from "../../assets/images/freightsimg.png";
import ApiConfig from "../../api/ApiConfig";
import eyes from "../../assets/images/eyes.svg";
import Loader from "../../sharedComponent/Loader";

const CompleteFreights = ({ completedFrights }) => {
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <div style={{ margin: "20px" }}>
        <div className="  col-md-6  mb-3   mb-3 ">
          <div className="">
            <div
              className=""
              style={{
                width: "1165px",
                overflow: "auto",
                height: "83vh",
              }}
            >
              {/* <ProgressBar progress={progress} /> */}
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <table className="dataList dataTable no-footer">
                  <thead>
                    <tr>
                    <th><label>Reference No</label></th>
                      <th>
                        <label>vehicle no</label>
                      </th>
                      {/* <th>
                        <label>tr id</label>
                      </th> */}
                      <th>
                        <label>Start City</label>
                      </th>
                      <th>
                        <label>Delivery Date</label>
                      </th>
                      <th>
                        <label>Status</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {completedFrights &&
                      completedFrights.length &&
                      completedFrights.map((freight, index) => {
                        return (
                          <tr
                            className="Rowbgcolor p-2"
                            key={"freight" + index}
                          >
                            <td>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                  <img
                                    src={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      freight.trip_image
                                    }
                                    alt=""
                                    className="card-rsponsive-img"
                                  />
                                  <h5 className="my-free ms-3">
                                    {freight.trip_reference_no}
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              {freight.vehicle_number}
                            </td>
                            {/* <td>{freight.transporter_id}</td> */}
                            <td>
                              {freight.trip_start_city +
                                " - " +
                                freight.trip_end_city}
                            </td>
                            <td id="trip_delivery_date">
                              {freight.trip_delivery_date}
                            </td>
                            <td id="trip_status">{freight.vehicle_status}</td>
                            <td id="">
                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <img className="" src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="/GPS_shipper"
                                      state={freight}
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        View in Map
                                        <img
                                          src={dropdown_arrow_blue}
                                          className="ongoingarrow"
                                          alt=""
                                        />
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/ViewDetails"
                                      state={freight}
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      Call
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompleteFreights;
