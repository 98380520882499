import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import "../assets/css/Transportvehicle.scss";
import ic_add from "../assets/images/Trasnvehicle/ic_add.svg";
import ic_import from "../assets/images/Trasnvehicle/ic_excel.svg";
import ic_options from "../assets/images/Trasnvehicle/ic_options.svg";
// import ChangeDriver from "Transporter/";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Loader from "../sharedComponent/Loader";
import swal from "sweetalert";
import WhiteTolltip from "../sharedComponent/WhiteTolltip";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";

const RegisteredVehiclesListLogedin = () => {
  const [loading, seLoading] = useState(false);
  const [vehicleList, setvehicleList] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [selected, setSelected] = useState("All");
  const { mainClass, customerData, setApiCalling, tp_id } =
    useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    getVehicleList();
  }, []);
  useEffect(() => {
    $(".dataList").DataTable().destroy();
    if (selected === "All" || selected === "select") {
      setvehicleList(originalData);
    } else {
      setvehicleList(
        originalData.filter((data) => {
          if (selected === "inactive") return data.vehicle_status === "blocked";
          else return data.vehicle_status === selected;
        })
      );
    }
  }, [selected]);
  useEffect(() => {
    return () => {
      setvehicleList([]);
    };
  }, []);
  const exportToexcel = () => {
    var excelData = [
      [
        "S.No",
        "Company Name",
        "Driver Name",
        "Vehicle Type",
        "Model",
        "Plate No.",
      ],
    ];
    vehicleList.map((elt, index) => {
      excelData.push([
        index + 1,
        elt.owner_name,
        elt.driver_name,
        elt.vehicle_type,
        elt.vehicle_model_no,
        elt.plate_number,
      ]);
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "Vehicles_list.xlsx");
  };
  const blockvehicle = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_VEHICLE,
      JSON.stringify({ ...customerData, vehicle_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getVehicleList();
      }
    });
  };
  const unBlockvehicle = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_VEHICLE,
      JSON.stringify({ ...customerData, vehicle_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getVehicleList();
      }
    });
  };
  

  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });
  const changeDriver = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.CHANGE_DRIVER,
      JSON.stringify({
        ...customerData,
        vehicle_id: currentVehicle.vehicle_id,
        driver_id: id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
        $(".dataList").DataTable().destroy();
        getVehicleList();
        swal(res.json.message);
      })
      .catch((err) => {
        setApiCalling(false);
        console.log(err);
      });
  };

  const getVehicleList = () => {
    seLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.REGISTERED_VEHICLE_LIST,
      JSON.stringify({ transporter_id: tp_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setvehicleList(res.json.vehicle_list);
          setOriginalData(res.json.vehicle_list);
          setCurrentVehicle(res.json.vehicle_list[0]);
          seLoading(false);
        }
      })
      .catch((err) => {
        seLoading(false);
        console.log(err);
        navigate("/ServerError");
      });
  };
  return (
    <React.Fragment>
      <div className={`vehicle-list ms-0`}>
        <div>
          <div className="d-flex  ">
            <div className="input-group justify-content-between ">
              <div className="mx-3">
                <h4>Registered Vehicle List</h4>
              </div>
              <div className="icon-group mt-2 d-flex mr-5 ">
                <ul className="d-flex list-unstyled  gap-3 ">
                <li>
                    <Link to="/RegisterVehicle">
                      {/* <img src={ic_add} className="add" alt="send-load-icon" /> */}
                      <Button>Register New Vehicle</Button>
                    </Link>
                    {/* <WhiteTolltip /> */}
                  </li>
                  <li>
                    {" "}
                    <div className="form-outline searchBox  m-0 ">
                      <label htmlFor="select" className="mr-4">
                        Filter
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => setSelected(e.target.value)}
                      >
                        <option value={"All"}>All</option>
                        <option value={"available"}>Available</option>
                        <option value={"Busy"}>Busy</option>
                        <option value={"active"}>Active</option>
                        <option value={"inactive"}>Inactive</option>
                      </select>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>

          <div
            className=""
            style={{ width: "100%", overflow: "auto", padding: "30px 0" }}
          >
            {loading ? (
              <Loader loading={loading} />
            ) : (
              <table className="dataList " style={{ minWidth: "900px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No</label>
                    </th>
                    {/* <th>
                      <label>Owner Name</label>
                    </th> */}
                    <th>
                      <label>Company Name</label>
                    </th>
                    {/* <th>
                      <label>Driver Name</label>
                    </th> */}
                    <th>
                      <label>Vehicle Type</label>
                    </th>
                    <th>
                      <label>Model</label>
                    </th>
                    <th>
                      <label>Plate No.</label>
                    </th>
                    <th>
                      <label>Status</label>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleList.map((vehicle, index) => {
                    return (
                      <tr className="Rowbgcolor p-2" key={"vehicle" + index}>
                        <td>{index + 1}</td>
                        {/* <td >{vehicle.owner_name} </td> */}
                        <td>{vehicle.owner_name}</td>
                        {/* <td>{vehicle.driver_name}</td> */}
                        <td>
                          {vehicle.vehicle_type}
                          <span className="etc">...</span>{" "}
                        </td>
                        <td>{vehicle.vehicle_model_no}</td>
                        <td>{vehicle.plate_number}</td>
                        <td>{vehicle.vehicle_status}</td>
                        <td>
                          <div className="option-dropdown dropdown p-2">
                            <a
                              href=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() => setCurrentVehicle(vehicle)}
                            >
                              <img src={ic_options} alt="" />
                            </a>
                            <ul className="dropdown-menu ">
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() =>
                                    navigate(
                                      "/AddDeliveryVehicle/" + vehicle.vehicle_id
                                    )
                                  }
                                >
                                  Edit1{" "}
                                  <img
                                    src={dropdown_arrow_blue}
                                    className="arrowvehicle"
                                    alt=""
                                  />
                                </button>
                              </li>
                              <li>
                                <Link
                                  to={
                                    "/VehicleDriverDetailsHeader/" +
                                    vehicle.vehicle_id
                                  }
                                  state={{
                                    id: vehicle.vehicle_id,
                                    transporter_id: vehicle.owner_id,
                                    driver_details: vehicle.driver_details,
                                  }}
                                  className="text-decoration-none"
                                >
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    View Details
                                  </button>
                                </Link>
                              </li>
                              {vehicle.vehicle_status === "block" ? (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      unBlockvehicle(vehicle.vehicle_id);
                                    }}
                                  >
                                    Unblock
                                  </button>
                                </li>
                              ) : (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      blockvehicle(vehicle.vehicle_id);
                                    }}
                                  >
                                    Block
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {vehicleList?.length > 0 && (
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="#">
              <button type="submit" className="cancel-btn">
                Cancel
              </button>
            </a>
            <Link to="/RegisteredDriversList">
              <button type="submit" className="continue-btn">
                Continue
              </button>
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RegisteredVehiclesListLogedin;
