import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ApiConfig from "../api/ApiConfig";
import { simplePostCall } from "../api/ApiServices";
// import "../assets/css/login.scss";
import logo from "../assets/images/Login/logo.svg";
const Login = () => {
  let ForgotData = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ username: "" });
  const [errMsg, setErrMsg] = useState({ username: "" });
  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (state.email === "") {
      setErrMsg({ ...errMsg, email: "Enter Email ID " });
      return;
    } else {
      simplePostCall(ApiConfig.FORGET_PASSWORD, JSON.stringify({ ...state }))
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }

          if (res.result === true) {
            navigate("/ResetPassword", { state: { ...state } });
            swal(res.message);
          } else {
            swal(res.message);
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <section className=" container-fluid login-main-section ">
      <div className="row">
        <div className="col-lg-6 col-md-6 left-section">
          <div className="left-content">
            <img src={logo} alt="" />
            <h3 className="mt-3 heading">Abay Logistics</h3>
            <p className="left-login-paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 right-section">
          <div className="right-content ">
            <h3>Forget Password</h3>

            <p className="welcome-login">
              Welcome to <span>Abay Logistics</span>
            </p>
            <form action="">
              <div class="mb-3 row">
                <label for="inputEmail" class="col-sm-12 col-form-label">
                  Email
                </label>
                <div class="col-sm-12">
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, username: "" });
                      setState({ ...state, username: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="login-btn ">
                <Link
                  to="#"
                  className=" col-sm-12 col-md-12 col-lg-12"
                  onClick={resetPassword}
                >
                  Submit
                </Link>
              </div>
              <div
                className="forgot pt-3"
                style={{
                  textAlign: "center",
                }}
              >
                <Link to="/AddTransporter">
                  Don't have an account?{" "}
                  <span
                    style={{
                      color: "#8697c4",
                    }}
                  >
                    Register
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
