import { Link, NavLink } from "react-router-dom";
import ic_trucks from "../../assets/images/Dashboard/ic_trucks.svg";
import ic_drivers from "../../assets/images/Dashboard/ic_drivers.svg";
import ic_contracts_1 from "../../assets/images/Dashboard/ic_contracts_1.svg";
import ic_contracts from "../../assets/images/Dashboard/ic_contracts.svg";
import ic_call from "../../assets/images/Dashboard/ic_call.svg";
import user_1 from "../../assets/images/Dashboard/user_1.svg";
import banner_graphic from "../../assets/images/Dashboard/banner_graphic.svg";
import Chart from "react-apexcharts";
import { Modal, Button } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import moment from "moment";

const Dashboard = () => {
  const [show, setShow] = useState(true);
  const { mainClass } = useContext(AppContext);
  const [tariffImprotList, setTariffImportList] = useState([]);
  const [tariffExportList, setTariffExportList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [graphDetails, setGraphDetails] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "frieghts",
        data: [],
      },
    ],
    colors: ["#8697c4"],
  });

  const handleClose = () => setShow(false);
  const { customerData, dashBoardData, setDashBoardData } =
    useContext(AppContext);
  useEffect(() => {
    TarrifImportList();
    TarrifExportList();
  }, []);
  const TarrifExportList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_EXPORT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setTariffExportList(res.json.tariff_export_list);
      setLoading(false);
    });
  };
  const TarrifImportList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_IMPORT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setTariffImportList(res.json.tariff_import_list);
      setLoading(false);
    });
  };

  const getDashboardDetails = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DASHBOARD,
      JSON.stringify({
        ...customerData,
        from_date: dates.startDate,
        to_date: dates.endDate,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) setDashBoardData(res.json);
      setGraphDetails({
        ...graphDetails,
        options: {
          ...graphDetails.options,
          xaxis: {
            categories: res.json.graph_details.graph.map((data) => data.date),
          },
        },

        series: [
          {
            name: "freights",
            data: res.json.graph_details.graph.map((data) => {
              return data.freights;
            }),
          },
        ],
      });
    });
  };
  useEffect(() => {
    getDashboardDetails();
  }, [dates]);
  return (
    <>
      <main className={`main-section-dashboard scroll-style ${mainClass} `}>
        <section>
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-content">
                <div className="row">
                  <div className="col-lg-12">
                    <section className="banner-dashboard">
                      <div className="row">
                        <div className="col-lg-7 col-sm-7 banner-left">
                          <span>Welcome back,</span>
                          <h3 className="heading-banner">
                            {dashBoardData.user_name}
                          </h3>
                          <ul className="paragarph-banner mb-2">
                            <NavLink to="/RegisteredVehicleList">
                              <li>Transporter can Add / Replace Vehicle</li>
                            </NavLink>
                            <NavLink to="/RegisteredDriverList">
                              <li>Transporter can Add / Replace Driver</li>
                            </NavLink>
                            <NavLink to="/OfferGoodsDirect">
                              <li>
                                Transporter can accept offer load and send and
                                offer to admin by offering vehicle
                              </li>
                            </NavLink>
                            <NavLink to="/OrderConfirmation">
                              <li>Transporter can view Order Confirmation</li>
                            </NavLink>
                          </ul>
                          <div>
                            <Link
                              to="/OfferGoodsDirect"
                              className="get-start-btn"
                            >
                              Get Started With Offer Goods
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-5 col-sm-5 banner-right">
                          <img
                            className="banner-img"
                            src={banner_graphic}
                            alt=""
                          />
                        </div>
                      </div>
                    </section>
                    <div className="dashboard-card">
                      <div className="row">
                        {/* left card */}
                        <div className="col-lg-6 ">
                          <div className=" card left-card-section mt-4 card-style">
                            <div className="graph-chart ">
                              <div className="card-body">
                                <div className="top-content d-flex justify-content-between">
                                  <div className="top-left"></div>
                                  <div className="top-right d-flex">
                                    <p className="m-0 mt-2 me-2">From</p>
                                    <input
                                      className="form-control"
                                      type="date"
                                      value={dates.startDate}
                                      onChange={(e) => {
                                        // setErrMsg({ ...errMsg, username: "" });
                                        setDates({
                                          ...dates,
                                          startDate: e.target.value,
                                        });
                                      }}
                                    />
                                    <p className="ms-2 mt-2 me-2">to</p>
                                    <input
                                      className="form-control"
                                      type="date"
                                      value={dates.endDate}
                                      onChange={(e) => {
                                        // setErrMsg({ ...errMsg, username: "" });
                                        setDates({
                                          ...dates,
                                          endDate: e.target.value,
                                        });
                                      }}
                                    />
                                    {/* <DateRangePicker placeholder="Select Date Range" /> */}
                                  </div>
                                </div>
                                <hr />

                                <div className="middel-content chart">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <Chart
                                        options={graphDetails.options}
                                        series={graphDetails.series}
                                        type="line"
                                        height={200}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="bottom-content d-flex justify-content-between">
                                  <div className="left">
                                    <h5 className="left-count">
                                      {graphDetails &&
                                        graphDetails.total_freights}
                                    </h5>
                                    <label>Total freights</label>
                                  </div>
                                  <div className="center">
                                    <h5 className="left-count">
                                      {graphDetails &&
                                        graphDetails.this_week_count}
                                    </h5>
                                    <label>Delivered This week</label>
                                  </div>
                                  <div className="right">
                                    <h5 className="left-count">
                                      {graphDetails &&
                                        graphDetails.total_revenue}
                                    </h5>
                                    <label>Total revenue</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* right card */}
                        <div className="col-lg-6">
                          <div className="right-card-section">
                            <div className="row">
                              <div className="col-lg-6 col-md-6  purple-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between">
                                      <div>
                                        <h4 className="purple">
                                          {dashBoardData.vehicles}
                                        </h4>
                                        <p className="count-name">Vehicles</p>
                                      </div>

                                      <div>
                                        <img src={ic_trucks} alt="" />
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          {dashBoardData.active_vehicles}
                                        </span>
                                      </div>
                                      <div className="right">
                                        <Link to="/VehicleList">
                                          <a href="">View All</a>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 orange-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between">
                                      <div>
                                        <h4 className="orange">
                                          {dashBoardData.drivers}
                                        </h4>
                                        <p className="count-name">Driver</p>
                                      </div>

                                      <div>
                                        <img src={ic_drivers} alt="" />
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          {dashBoardData.active_drivers}
                                        </span>
                                      </div>
                                      <div className="right">
                                        <Link to="/driverlist">
                                          <a href="">View All</a>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 green-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between">
                                      <div>
                                        <h4 className="green">{dashBoardData.offer_goods}</h4>
                                        <p className="count-name">
                                          Offer Loads
                                        </p>
                                      </div>

                                      <div>
                                        <img src={ic_contracts} alt="" />
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="card-body bottom d-flex justify-content-between">
                                      {/* <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          23
                                        </span>
                                      </div> */}
                                      <div className="right">
                                        <Link to="/OfferGoodsDirect">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 blue-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between">
                                      <div>
                                        <h4 className="blue">
                                          {dashBoardData.offered_vehicles}
                                        </h4>
                                        <p className="count-name">
                                          Offered Vehicles
                                        </p>
                                      </div>

                                      <div>
                                        <img src={ic_contracts_1} alt="" />
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="card-body bottom d-flex justify-content-between">
                                      {/* <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          23
                                        </span>
                                      </div> */}
                                      <div className="right">
                                        <Link to="/OfferedVehicles">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Ongoing and Notification card */}
                    <div className="details-card">
                      <div className="row">
                        {/* left card */}
                        {/* <div className="col-lg-6">
                          <div className="card card-style left-card-dashboard scroll-style mt-4">
                            <div className="card-body">
                              <div className="card_title d-flex justify-content-between">
                                <h4 className="ongoing-heading common-heading">
                                  Ongoing Freights
                                </h4>
                                <div className="right-top d-flex justify-content-between ">
                                  <div>
                                    <Link
                                      to="/FreightsHeaders"
                                      className="blue_link m-2"
                                    >
                                      List View
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                      to="/GPS_Shipper"
                                      className="blue_link m-2"
                                    >
                                      Map View
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="user_block ">
                                {dashBoardData.ongoing_freights &&
                                  dashBoardData.ongoing_freights.length &&
                                  dashBoardData.ongoing_freights.map(
                                    (firght) => {
                                      return (
                                        <>
                                          <div className="d-flex justify-content-between align-items-start mb-2">
                                            <div className="d-flex align-items-start">
                                              <img
                                                src={user_1}
                                                className="me-2"
                                                alt=""
                                              />{" "}
                                              <div className="ms-3">
                                                <span className="name">
                                                  {firght.trip_name}
                                                </span>
                                                <p className="location">
                                                  {firght.trip_start_country +
                                                    " " +
                                                    firght.trip_start_city}{" "}
                                                  -{" "}
                                                  {firght.trip_end_country +
                                                    " " +
                                                    firght.trip_end_city}
                                                </p>
                                                <Link
                                                  to="/ViewDetails"
                                                  state={firght}
                                                >
                                                  <a
                                                    href=""
                                                    className="blue_link"
                                                  >
                                                    View Details
                                                  </a>
                                                </Link>
                                              </div>
                                            </div>
                                            <a href="#">
                                              <img src={ic_call} alt="" />
                                            </a>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* right card */}
                        {/* <div className="col-lg-6">
                          <div className=" card-style right-card-dashboard scroll-style mt-4">
                            <div className="card-body">
                              <div className="card_title d-flex justify-content-between">
                                <h4 className="ongoing-heading common-heading">
                                  Recent Activities
                                </h4>
                                <Link to="/Notifications" className="blue_link">
                                  View All
                                </Link>
                              </div>

                              <div className="notification-all ">
                                {dashBoardData.notifications &&
                                  dashBoardData.notifications.map(
                                    (notification, index) => {
                                      return (
                                        <>
                                          <hr />
                                          <div className=" mb-2">
                                            <h5 className="notification-heading">
                                              {notification.notification_id}{" "}
                                            </h5>
                                            <p className="notification-paragraph">
                                              {notification.notification_text}
                                            </p>
                                            <p className="date-time text-end">
                                              {moment(
                                                new Date(
                                                  notification.notification_date +
                                                    " " +
                                                    notification.notification_time
                                                )
                                              ).fromNow()}
                                            </p>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="rates-main">
                          <div className="shipper">
                            <div className="heading-top">
                              <div className="left">
                                Abay Logistics plc. Transport tariff
                                Chart(Import)
                              </div>
                              <div className="right">
                                <Link to="/TransporterRatesImport">
                                  View All
                                </Link>
                              </div>
                            </div>
                            <div className="heading-top mt-3">
                              <div className="left">Departure--Djibouti</div>
                            </div>

                            <div className="offerLoadsMainLists">
                              <div
                                className="mainLoadTable"
                                style={{ overflow: "auto" }}
                              >
                                <table
                                  className="dataList"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        <label>S.No.</label>
                                      </th>
                                      <th>
                                        <label>Destination</label>
                                      </th>
                                      <th>
                                        <label>Km</label>
                                      </th>
                                      <th>
                                        <label>Currency</label>
                                      </th>

                                      <th>
                                        <label>Unit of Measurement</label>
                                      </th>
                                      <th className="">
                                        <label>Tariff (Birr) </label>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tariffImprotList &&
                                      tariffImprotList.map(
                                        (tariffImprotListData, index) => {
                                          return (
                                            <tr
                                              className="Rowbgcolor"
                                              key={
                                                "tariffImprotListData" + index
                                              }
                                            >
                                              <td>
                                                <label>{index + 1}</label>
                                              </td>
                                              <td>
                                                <label>
                                                  {
                                                    tariffImprotListData.tariff_import_destination
                                                  }
                                                </label>
                                              </td>
                                              <td>
                                                <label>
                                                  {
                                                    tariffImprotListData.tariff_import_km
                                                  }
                                                </label>
                                              </td>
                                              <td>
                                                <label>
                                                  {
                                                    tariffImprotListData.currency_code
                                                  }
                                                </label>
                                              </td>

                                              <td>
                                                <label>
                                                  {
                                                    tariffImprotListData.unit_name
                                                  }
                                                </label>
                                              </td>

                                              <td>
                                                <label>
                                                  {
                                                    tariffImprotListData.tariff_import_tariff
                                                  }
                                                </label>
                                              </td>
                                              <td>
                                                <label></label>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="shipper">
                            <div className="heading-top">
                              <div className="left">
                                Abay Logistics plc. Transport tariff Chart
                                (Export){" "}
                              </div>
                              <div className="right">
                                <Link to="/TransporterRatesExport">
                                  View All
                                </Link>
                              </div>
                            </div>
                            <div className="heading-top mt-3">
                              <div className="left">Departure--Djibouti</div>
                            </div>

                            <div className="offerLoadsMainLists">
                              <div
                                className="mainLoadTable"
                                style={{ overflow: "auto" }}
                              >
                                <table
                                  className="dataList"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        <label>S.No.</label>
                                      </th>
                                      <th>
                                        <label>Destination</label>
                                      </th>
                                      <th>
                                        <label>Km</label>
                                      </th>
                                      <th>
                                        <label>Currency</label>
                                      </th>

                                      <th>
                                        <label>Unit of Measurement</label>
                                      </th>
                                      <th className="">
                                        <label>Tariff (Birr) </label>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tariffExportList &&
                                      tariffExportList.map(
                                        (tariffExportListData, index) => {
                                          return (
                                            <tr
                                              className="Rowbgcolor"
                                              key={
                                                "tariffExportListData" + index
                                              }
                                            >
                                              <td>
                                                <label>{index + 1}</label>
                                              </td>
                                              <td>
                                                <label>
                                                  {
                                                    tariffExportListData.tariff_export_destination
                                                  }
                                                </label>
                                              </td>
                                              <td>
                                                <label>
                                                  {
                                                    tariffExportListData.tariff_export_km
                                                  }
                                                </label>
                                              </td>
                                              <td>
                                                <label>
                                                  {
                                                    tariffExportListData.currency_code
                                                  }
                                                </label>
                                              </td>

                                              <td>
                                                <label>
                                                  {
                                                    tariffExportListData.unit_name
                                                  }
                                                </label>
                                              </td>

                                              <td>
                                                <label>
                                                  {
                                                    tariffExportListData.tariff_export_tariff
                                                  }
                                                </label>
                                              </td>
                                              <td>
                                                <label></label>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dashboard;
