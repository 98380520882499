import "./assets/css/main.scss";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import React from "react";
import Login from "./pages/Login";
import "./assets/css/style.scss";
import VehicleDriverDetailsHeader from "./pages/vehicles/VehicleDriverDetailsHeader";
import DriverDetails from "./pages/Driver/DriverDetails";
import VehiclesDetails from "./pages/vehicles/VehiclesDetails";
import GoodsDetails from "./sharedComponent/GoodsDetails";
import FAQ from "./pages/faq/FAQ";
import ContactUs from "./sharedComponent/ContactUs";
import TermsAndConditions from "./sharedComponent/TermsAndConditions";
import AbhayHeader from "./sharedComponent/AbhayHeader";
import Sidebar from "./sharedComponent/Sidebar";
import CompleteFreights from "./pages/fright/CompleteFreights";
import OngoingFreights from "./pages/fright/OngoingFreights";
import VehicleList from "./pages/vehicles/VehicleList";
import DriverList from "./pages/Driver/DriverList";
import Rating from "./pages/Transporter/Rating";
import Profile from "./pages/profile/Profile";
import GPS_Shipper from "./pages/liveTracking/GPS_Shipper";
import OngoingFreightsMenu from "./pages/fright/OngoingFreightsMenu";
import OrderConfirmation from "./sharedComponent/OrderConfirmation";
import FreightsHeaders from "./pages/fright/Freightsheader";
import Contracts from "./pages/contactUs/Contracts";
import ViewDetails from "./pages/fright/ViewDetails_Goods_Driver_Vehicle";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import OfferVehicles from "./sharedComponent/OfferVehicles";
import VehicleRequestOnlineAuction from "./pages/onlineAuction/VehicleRequestOnlineAuction";
import TransporterAuction from "./pages/onlineAuction/TransporterAuction";
import OfferGoodsDirect from "./pages/directOrder/OfferGoodsDirect";
import OfferedVehicles from "./pages/directOrder/OfferedVehicles";
import AddDelivery from "./sharedComponent/AddDelivery";
import AddTransporterSecond from "./sharedComponent/AddTransporterSecond";

import ViewOrder from "./sharedComponent/ViewOrder";
import { useEffect, useState } from "react";
import OfferedVehiclesViewDetails from "./pages/directOrder/OfferedVehiclesViewDetails";
import Dashboard from "./pages/dashboard/Dashboard";
import { Reports } from "./pages/fright/Reports";
import SelectReportTypes from "./pages/fright/SelectReportTypes";
import UpcomingFreights from "./pages/fright/UpcomingFreights";
import Notifications from "./pages/notification/Notifications";
import { Modal } from "react-bootstrap";
import TransporterRatesImport from "./pages/Rates/TransporterRatesImport";
import TransporterRatesExport from "./pages/Rates/TransporterRatesExport";
import ForgetPassword from "./sharedComponent/ForgetPassword";
import ResetPassword from "./sharedComponent/ResetPassword";
import RegisteredVehicleList from "./pages/Register/Vehicles/VehicleList";
import RegisteredDriverList from "./pages/Register/Driver/DriverList";
import AddVehicles from "./pages/Register/Vehicles/AddVehicles";
import AddDriver from "./pages/Register/Driver/AddDriver";
import EditProfile from "./sharedComponent/EditProfile";
import RegisterVehicle from "./sharedComponent/RegisterVehicle";
import RegisterDriver from "./sharedComponent/RegisterDriver";
import RegisteredVehiclesList from "./sharedComponent/RegisteredVehicleList";
import RegisteredDriversList from "./sharedComponent/RegisteredDriverList";
import ChangePassword from "./sharedComponent/ChangePassword";
import ServerError from "./sharedComponent/ServerError";
import PageNotFound from "./sharedComponent/PageNotFound";
import ViewOrderOfferedVehicles from "./sharedComponent/ViewOrderOfferedVehicles";
import AddDeliveryVehicle from "./pages/Register/Vehicles/AddDeliveryVehicle";
import RegisteredVehiclesListLogedin from "./sharedComponent/RegisteredVehicleListLogedin";

function App() {
  const currentRoute = useLocation().pathname;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );

  const handleClose = () => setShow(false);
  useEffect(() => {
    if (!logedIn) {
      if (
        currentRoute.includes("AddDelivery") ||
        currentRoute.includes("RegisterVehicle") ||
        currentRoute.includes("RegisteredVehiclesList") ||
        currentRoute.includes("ChangePassword") ||
        currentRoute.includes("RegisterDriver") ||
        currentRoute.includes("ForgetPassword") ||
        currentRoute.includes("ResetPassword")
      ) {
        navigate(currentRoute);
      } else navigate("/");
    } else {
      setShow(true);
      navigate("Dashboard");
    }
  }, [logedIn]);
  useEffect(() => {
    let pathvariable = window.location.pathname.toString();

    if (!logedIn) {
      if (
        currentRoute === "/AddDelivery" ||
        currentRoute === "/RegisterVehicle" ||
        currentRoute === "/RegisteredVehiclesList" ||
        currentRoute === "/RegisteredDriversList" ||
        currentRoute === "/RegisterDriver" ||
        currentRoute === "/ForgetPassword" ||
        currentRoute === "/ResetPassword" ||
        currentRoute === "/ServerError"
      ) {
        navigate(currentRoute);
      } else if (pathvariable !== ("/AddDelivery") ||
      pathvariable !== ("/RegisterVehicle") ||
      pathvariable !== ("/RegisteredVehiclesList") ||
      pathvariable !== ("/RegisteredDriversList") ||
      pathvariable !== ("/RegisterDriver") ||
      pathvariable !== ("/ForgetPassword") ||
      pathvariable !== ("/ResetPassword") ||
      pathvariable !== ("/ServerError")
        ) {
        navigate("/")
      } else navigate("/");
    } else {
      navigate(currentRoute);
    }
  }, [currentRoute]);
  return (
    <div className="App">
     
      {currentRoute !== "/" && currentRoute !== "Login" && (
        <>
          {!(
            window.location.pathname === "/AddDelivery" ||
            window.location.pathname === "/RegisterVehicle" ||
            window.location.pathname === "/RegisteredVehiclesList" ||
            window.location.pathname === "/RegisteredDriversList" ||
            window.location.pathname === "/RegisterDriver" ||
            window.location.pathname === "/ForgetPassword" ||
            window.location.pathname === "/ResetPassword" ||
            window.location.pathname === "/ServerError"
          ) ? (
            <>
              <Sidebar />
              <AbhayHeader setLoggedIn={setLoggedIn} />
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <Routes>
        <Route
          path={"/" || "/Login"}
          element={<Login setLoggedIn={setLoggedIn} />}
        />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route
          path="VehicleDriverDetailsHeader"
          element={<VehicleDriverDetailsHeader />}
        />
          <Route path=":id" element={<VehicleDriverDetailsHeader />} >
        </Route>

        <Route path="DriverDetails/" element={<DriverDetails />}>
          <Route path=":id" element={<DriverDetails />} />
        </Route>
        <Route path="ForgetPassword" element={<ForgetPassword />} />
        <Route path="ResetPassword" element={<ResetPassword />} />
        <Route path="VehiclesDetails" element={<VehiclesDetails />} />
        <Route
          path="TransporterRatesImport"
          element={<TransporterRatesImport />}
        />
        <Route
          path="TransporterRatesExport"
          element={<TransporterRatesExport />}
        />
        <Route path="GoodsDetails" element={<GoodsDetails />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="TermsAndConditions" element={<TermsAndConditions />} />
        <Route
          path="AddDelivery"
          element={<AddDelivery logedIn={logedIn} />}
        />
        <Route path="EditProfile" element={<EditProfile logedIn={logedIn} />} />
        <Route path="RegisterVehicle" element={<RegisterVehicle />} />
        <Route path="RegisterDriver" element={<RegisterDriver />} />
        <Route path="AddTransporterSecond" element={<AddTransporterSecond />} />
        <Route path="Notifications" element={<Notifications />} />
        <Route path="AbhayHeader" element={<AbhayHeader />} />
        <Route path="Sidebar" element={<Sidebar />} />
        <Route path="CompleteFreights" element={<CompleteFreights />} />
        <Route path="OngoingFreights" element={<OngoingFreights />} />
        <Route path="UpcomingFreights" element={<UpcomingFreights />} />
        <Route path="OngoingFreightsMenu" element={<OngoingFreightsMenu />} />
        <Route path="TransporterAuction" element={<TransporterAuction />} />
        <Route path="VehicleList" element={<VehicleList />} />
        <Route
          path="RegisteredVehicleList"
          element={<RegisteredVehicleList />}
        />
        <Route path="RegisteredDriverList" element={<RegisteredDriverList />} />
        <Route path="AddVehicles/" element={<AddVehicles logedIn={logedIn} />}>
          <Route path=":id" element={<AddVehicles />} />
        </Route>
        
        <Route path="AddDeliveryVehicle/" element={<AddDeliveryVehicle />}>
          <Route path=":id" element={<AddDeliveryVehicle />} />
        </Route>
        <Route path="AddDriver" element={<AddDriver logedIn={logedIn} />}>
          <Route path=":id" element={<AddDriver />} />
        </Route>
        <Route path="OrderConfirmation" element={<OrderConfirmation />} />
        <Route path="OrderConfirmationOnline" element={<OrderConfirmation />} />
        <Route path="DriverList" element={<DriverList />} />
        <Route path="Reports" element={<Reports />} />
        <Route path="SelectReportTypes" element={<SelectReportTypes />} />
        <Route path="Rating" element={<Rating />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="GPS_Shipper" element={<GPS_Shipper />} />
        <Route path="FreightsHeaders" element={<FreightsHeaders />} />
        <Route path="Contracts" element={<Contracts />} />
        <Route path="ViewDetails" element={<ViewDetails />} />
        <Route path="OfferGoodsDirect" element={<OfferGoodsDirect />} />
        <Route path="OfferVehicles" element={<OfferVehicles />} />
        <Route
          path="VehicleRequestOnlineAuction"
          element={<VehicleRequestOnlineAuction />}
        />
        <Route path="OfferedVehicles" element={<OfferedVehicles />} />
        <Route path="OfferedVehiclesOnline" element={<OfferedVehicles />} />
        <Route
          path="RegisteredVehiclesList"
          element={<RegisteredVehiclesList />}
        />
        <Route
          path="RegisteredVehiclesListLogedin"
          element={<RegisteredVehiclesListLogedin />}
        />
        <Route
          path="RegisteredDriversList"
          element={<RegisteredDriversList />}
        />
        <Route path="ChangePassword" element={<ChangePassword />} />
        <Route path="ViewOrder" element={<ViewOrder />} />
        <Route
          path="OfferedVehiclesViewDetails/"
          element={<OfferedVehiclesViewDetails />}
        />
        <Route
          path="ViewOrderOfferedVehicles"
          element={<ViewOrderOfferedVehicles />}
        />
          <Route path=":id" element={<OfferedVehiclesViewDetails />} />
        
        <Route path="ServerError"  element={<ServerError/> }/>
        <Route path="*"  element={logedIn ? <PageNotFound/> : <Login setLoggedIn={setLoggedIn} /> } />
      </Routes>
    </div>
  );
}

export default App;
