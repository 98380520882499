import React, { useState, useEffect, useContext } from "react";
import AbhayHeader from "../../sharedComponent/AbhayHeader";
import Sidebar from "../../sharedComponent/Sidebar";
import banner_bg from "../../assets/images/Dashboard/banner_bg.png";
import profile_pic from "../../assets/images/Skyscrapers.jpg";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { Link } from "react-router-dom";

const Profile = () => {
  const { mainClass, profile } = useContext(AppContext);
  const basicInfo = profile.basic_info;

  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={"main-profile-section " + mainClass} id="cxMain">
        <div className="prfile-body-section common-body">
          <div className="row">
            <div className="profile-banner">
              <div className="banner-img d-flex justify-content-end">
                <Link
                  className="card-heading text-decoration-none m-3"
                  to="/EditProfile"
                >
                  Edit
                </Link>
              </div>
              <div className="details-profile">
                <div className="profile-img">
                  <img
                    src={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                        (basicInfo && basicInfo.profile_pic) || profile_pic
                    }
                    alt=""
                  />{" "}
                  <span className="name-profile mt-2">
                    {profile.basic_info.company_name}
                  </span>
                </div>
                {/* BASIC INFO */}
                <div className=" profile-basic-info row mt-4">
                  <h5 className="card-heading">Basic Info</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Company Name</label>
                    <p className="data">
                      {profile.basic_info && profile.basic_info.company_name}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Company Type</label>
                    <p className="data">
                      {profile.basic_info && profile.basic_info.company_type}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Total Fleet Size</label>
                    <p className="data">
                      {profile.basic_info &&
                        profile.basic_info.total_fleet_size}
                    </p>
                  </div>
                </div>
                {/* CONTACT PORSON */}
                <div className="profile-contact-person row mt-4">
                  <h5 className="card-heading">Contact Person</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Name</label>
                    <p className="data">
                      {profile.contact_person &&
                        profile.contact_person &&
                        profile.contact_person.name}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Responsibility</label>
                    <p className="data">
                      {profile.contact_person &&
                        profile.contact_person.responsibility}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Phone Number</label>
                    <p className="data">
                      {profile.contact_person && profile.contact_person.phone}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Email</label>
                    <p className="data">
                      {profile.contact_person && profile.contact_person.email}
                    </p>
                  </div>
                </div>
                {/* ADDRESS */}
                <div className="profile-address row mt-4">
                  <h5 className="card-heading">Address</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Country</label>
                    <p className="data">
                      {profile.address && profile.address.country}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Region</label>
                    <p className="data">
                      {profile.address && profile.address.region}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Phone </label>
                    <p className="data">
                      {profile.address && profile.address.phone}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Alternative Phone</label>
                    <p className="data">
                      {profile.address && profile.address.alternative_phone}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Email</label>
                    <p className="data">
                      {profile.address && profile.address.email}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">P.O.Box</label>
                    <p className="data">
                      {profile.address && profile.address.po_box}
                    </p>
                  </div>
                </div>
                {/* Documents */}
                {/* <div className="profile-contact-person row mt-4">
                  <h5 className="card-heading">Documents</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Business License</label>
                    <div className="menu-option d-flex align-items-start">
                      <img
                        src={
                          ApiConfig.BASE_URL_FOR_IMAGES +
                            (profile.documents &&
                              profile.documents.business_license) ||
                          document_image
                        }
                        alt=""
                        className="documentImages"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Grade Certificate</label>
                    <div className="menu-option d-flex align-items-start">
                      <img
                        src={
                          ApiConfig.BASE_URL_FOR_IMAGES +
                            (profile.documents &&
                              profile.documents.grade_certificate) ||
                          document_image
                        }
                        alt=""
                        className="documentImages"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">TIN</label>
                    <div className="menu-option d-flex align-items-start">
                      <img
                        src={
                          ApiConfig.BASE_URL_FOR_IMAGES +
                            (profile.documents &&
                              profile.documents.tin_document) || document_image
                        }
                        alt=""
                        className="documentImages"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
