import React, { useContext } from "react";
import "../../assets/css/ongoingfreights.scss";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import demo_img from "../../assets/images/AddVehicles/ic_vehicle.svg";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";
import { useState } from "react";

const UpcomingFreights = ({ upCommingFrights, getUpcommingFrights }) => {
  const [loading, setLoading] = useState(false);

  const { customerData, apiCalling, setApiCalling } = useContext(AppContext);
  const initiateFright = (load_id, vehicle_id, trip_vehicle_id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.INITIATE_FRIGHT,
      JSON.stringify({ ...customerData, load_id, vehicle_id, trip_vehicle_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
        swal(res.json.message);
        getUpcommingFrights();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div style={{ margin: "20px" }}>
        <div className="  col-md-6  mb-3   mb-3 ">
          <div className="">
            <div
              className=""
              style={{
                width: "1165px",
                overflow: "auto",
                height: "83vh",
              }}
            >
              {/* <ProgressBar progress={progress} /> */}
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <table className="dataList dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Ref No</th>
                      <th>Plate No</th>
                      <th>Driver id</th>
                      <th>
                        <label>Start City</label>
                      </th>
                      <th>
                        <label>Delivery Date</label>
                      </th>
                      {/* <th>
                        <label>End Date</label>
                      </th> */}

                      <th>
                        <label>Status</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {upCommingFrights && upCommingFrights.length > 0 ? (
                      upCommingFrights.map((freight, index) => {
                        return (
                          <tr
                            className="Rowbgcolor p-2"
                            key={"freight" + index}
                          >
                            <td>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                  <img
                                    src={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      freight.trip_image
                                        ? ApiConfig.BASE_URL_FOR_IMAGES +
                                          freight.trip_image
                                        : demo_img
                                    }
                                    alt=""
                                    className="card-rsponsive-img"
                                  />
                                  <h5 className="my-free ms-3">
                                    {freight.trip_reference_no}
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              {freight.vehicle_number}
                            </td>
                            <td>{freight.driver_id}</td>
                            <td>
                              {freight.trip_start_city +
                                " - " +
                                freight.trip_end_city}
                            </td>
                            <td id="trip_start_date">
                              {freight.trip_delivery_date}
                            </td>
                            {/* <td id="trip_end_date">{freight.trip_end_date}</td> */}
                            <td id="trip_status">{freight.vehicle_status}</td>
                            <td id="">
                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <img className="" src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="#"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                        onClick={() =>
                                          initiateFright(freight.trip_id, freight.vehicle_id, freight.trip_vehicle_id)
                                        }
                                      >
                                        Initiate Fright
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/ViewDetails"
                                      state={freight}
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      Call
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <span className="text-danger">No data found</span>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpcomingFreights;
